<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
        detailHide,
      ]"
    >
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th>Action</vs-th>
          <vs-th sort-key="code">Payment</vs-th>
          <vs-th sort-key="invoice_code">Invoice</vs-th>
          <vs-th sort-key="warehouse_name">Warehouse</vs-th>
          <vs-th sort-key="type">Type</vs-th>
          <vs-th sort-key="bank_name">Bank Info</vs-th>
          <vs-th>Paid Value</vs-th>
          <!-- <vs-th>Unpaid Value</vs-th> -->
          <vs-th>Total Invoice Value</vs-th>
          <vs-th>Notes</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
          <vs-td class="whitespace-no-wrap">
              <template>
                <vx-tooltip text="Confirm Payment">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-check"
                    @click.stop="
                      handleConfirm(data[indextr])"
                  />
                </vx-tooltip>
                <vx-tooltip text="Reject Payment">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-x"
                    @click.stop="
                      handleReject(data[indextr])"
                  />
                </vx-tooltip>
              </template>
            </vs-td>
            <vs-td :data="data[indextr].payment_code">
              Number : {{tr.payment_code}}<br>
              Date : {{dateFormat(tr.payment_date)}}
            </vs-td>
            <vs-td :data="data[indextr].invoice_code">
              Number : {{tr.invoice_code}}<br>
              Date : {{dateFormat(tr.invoice_date)}}
            </vs-td>
            <vs-td>
              {{tr.warehouse_name}}
            </vs-td>
            <vs-td>
              {{tr.type}}
            </vs-td>
            <vs-td>
              {{tr.bank_name}} - {{tr.account_number}}<br>
              Ref Code : {{tr.reference_code}}
            </vs-td>
            <vs-td>
                {{tr.paid_invoice_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
            </vs-td>
            <!-- <vs-td>
                {{tr.unpaid_invoice_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
            </vs-td> -->
            <vs-td>
                {{tr.invoice_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
            </vs-td>
            <vs-td>
                {{tr.notes}}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      statusView: false,
      deleteId: null,
      piId: null,
      invCode: "",
      vendor: null,
      poCode: null,
      index1: 0,
      selected:null,
      data: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "updated_at",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      activePrompt: false,
      activePromptConfrm: false,
    };
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("YYYY-MM-DD");
      }
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    doApprove() {
      let param = {
        purchase_id: this.selected.purchase_invoice_id,
        purchase_payment_id: this.selected.id,
        status_payment: 8,
        status: "13",
      };
      this.$http
        .post("/api/v1/pi/change-status-payment", param)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData()
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleConfirm(selected){
      this.selected = selected
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to approve payment ?",
        accept: this.doApprove,
        cancel: () => {
          this.selected = null
        },
      });
    },
    doReject() {
      
      let param = {
        purchase_id: this.selected.purchase_invoice_id,
        purchase_payment_id: this.selected.id,
        status_payment: 5,
        status: "13",
      };
      this.$http
        .post("/api/v1/pi/change-status-payment", param)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData()
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleReject(selected){
      this.selected = selected
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to reject payment ?",
        accept: this.doReject,
        cancel: () => {
          this.selected = null
        },
      });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/list-payment", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.$userLogin.territory_id,
            status_payment: 7,
            territory_area_id: this.$userLogin.territory_area_id,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.record_total;
            this.table.totalPage = resp.data.page_total;
            this.table.totalSearch = resp.data.record_total_search;
            this.table.length = resp.data.record_total_per_page;
            this.table.data = resp.data.records;
            this.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}
</style>