<template>
  <vx-card title="Payment">
    <vs-tabs>
      <vs-tab label="Open">
        <div class="tab-text">
          <pending></pending>
        </div>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="tab-text">
          <approved></approved>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Pending from "./pending";
import Approved from "./approved";

export default {
  components: {
    Pending,
    Approved
  },
};
</script>